<template>
  <div style="width: 100%; height: 100vh; position: relative; overflow-y: auto;background-color: #FFFAFAFF">

    <div v-if="union_id && union_id!=''" style="padding: 0px 20px;box-sizing: border-box;width: 100%;margin-top: 15px">
      <div class="scan-btn" @click="gotoScan">
        <img src="../assets/scan_qrcode_icon.png" style="width: 20px;height: auto">
        <p class="scan-text">扫码加入新课程</p>
      </div>
    </div>
    <h3 style="text-align: left;padding-left: 20px">订单列表</h3>
    <div style="margin-top: 20px">
      <div
          v-for="(purchase, index) in dataList"
          :key="index"
          class="purchase-item"
          @click="goDetail(purchase)"
      >
        <div style="display: flex; flex-direction: row">
          <p
              style="
              flex: 1;
              color: #666666;
              font-size: 12px;
              font-family: PingFang SC-Semibold, PingFang SC;
            "
          >
            {{ formatPurchaseTime(purchase.create_time) }}
          </p>
          <p
              class="purchase-status"
              :style="formatOrderStatusStyle(purchase.points_deducted)"
          >
            {{ formatOrderStatus(purchase.points_deducted) }}
          </p>
        </div>
        <div style="width: 100%;height: 1px;background: #F5F5F5;margin-top: 10px;margin-bottom: 20px"></div>
        <div style="display: flex;flex-direction: row;">
          <div class="purchase-text" style="flex: 1">
            <div>
              <p v-if="purchase.purchase_type=='collection'">{{ purchase.content.collection_name }}</p>
              <p v-else>{{ purchase.content.album_name }}</p>
            </div>
            <div style="margin-top: 16px">
              <p v-if="purchase.purchase_type=='collection'" style="font-size: 16px;">
                <span style="text-decoration: line-through;">(原价￥{{ purchase.content.collection_price_show }})</span>
                ￥{{ purchase.merchant.price }}
              <p v-else style="font-size: 16px;"><span
                  style="text-decoration: line-through;">(原价￥{{ purchase.content.album_price }})</span>
                ￥{{ purchase.merchant.price }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getSignPackageFWH, getUserOrderList, startWXPayForFWH} from "../api/keneng";
import {getOpenId, getUnionId, getUserId} from "../utils/store";
import {formatDate} from "../utils/date";

export default {
  name: "home",
  data() {
    return {
      union_id: '',
      open_id: getOpenId(),
      dataList: [],
    }
  },
  mounted() {
    this.union_id = getUnionId()
    this.open_id =getOpenId()
    this.fetchData()
  },

  methods: {

    gotoScan() {
      window.location.href = 'https://login.kenengxueyuan.com/scan'
      // this.$router.push('/scan')
    },

    goWrapPay(purchase) {
      let price = purchase.merchant.price
      let content_name = ''
      if (purchase.purchase_type == 'collection') {
        content_name = purchase.content.collection_name
      } else {
        content_name = purchase.content.album_name
      }
      let params = {
        purchase_id: purchase._id.$id,
        price: price,
        open_id: this.open_id,
        subject: content_name
      }
      startWXPayForFWH(params).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.showWXPay(result.data.timeStamp, result.data.nonceStr, result.data.package, result.data.signType, result.data.paySign)
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    showWXPay(timestamp, nonceStr, package1, signType, paySign) {
      let that = this
      let url= window.location.href//this.$route.fullPath
      getSignPackageFWH(url).then((res) => {
        const result = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: [
            'chooseWXPay'
          ] // 必填，需要使用的JS接口列表
        })
      })
      wx.ready(() => {
        wx.chooseWXPay({
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: package1, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: paySign, // 支付签名
          success: (res) => {
            // 支付成功后的回调函数
            that.$message({
              message: '缴费成功',
              type: 'success'
            })
            that.fetchData()
          }
        })
      })
      wx.error((err) => {
        console.log(err)
      })
    },

    goDetail(purchase) {
      let merchant_id = purchase.merchant_id
      let content_id = purchase.content_id
      let purchase_status = 0

      if (!purchase.points_deducted) {
        this.goWrapPay(purchase)
        return
      }

      if (purchase.points_deducted) {
        purchase_status = 1
      }
      if (purchase.purchase_type == 'collection') {
        this.$router.push({
          path: '/share-collection',
          query: {
            collection_id: content_id,
            merchant_id: merchant_id,
            purchase_status: purchase_status
          }
        })
      } else {
        this.$router.push({
          path: '/share-album',
          query: {
            album_id: content_id,
            merchant_id: merchant_id,
            purchase_status: purchase_status
          }
        })
      }
    },

    formatPurchaseTime(create_time) {
      return formatDate(new Date(create_time * 1000), "yyyy-MM-dd");
    },

    formatOrderStatus(points_deducted) {
      if (points_deducted) {
        return "已支付";
      }
      return "待支付";
    },
    formatOrderStatusStyle(points_deducted) {
      var style = {};
      if (points_deducted) {
        style.color = "#009B65";
        style.background = "#D2FAEC";
        return style;
      }
      style.color = "#9E272C";
      style.background = "#FFD1D3";
      return style;
    },

    fetchData() {
      const user_id = getUserId();
      getUserOrderList(user_id).then((res) => {
        this.dataList = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.scan-text {
  margin-left: 15px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 28px;
}

.scan-btn {
  cursor: pointer;
  width: 100%;
  background: #FCC52D;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  border-radius: 26px 26px 26px 26px;
}

.purchase-item {
  margin-top: 16px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  padding: 14px 24px;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px 1px rgba(234, 234, 234, 0.2);
  border-radius: 10px 10px 10px 10px;
}

.purchase-status {
  background: #d0ddff;
  padding: 2px 8px;
  box-sizing: border-box;
  font-size: 11px;
  font-family: PingFang SC-Semibold, PingFang SC;
  border-radius: 10px 10px 10px 10px;
}

.purchase-text {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2E4FA1;
}
</style>
